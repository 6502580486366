.Score {
  //background-color: #000;
  //color: #fff;
  //padding: 5px;
  border-radius: 3px;
  width: auto;
  //display: block;
  //position: fixed;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.Score--bounce {
  animation: Score-keyframes--bounce .8s ease-out
}

@keyframes Score-keyframes--bounce {
  0%,
  to {
    background-color: rgba(255, 255, 255, 0);
    //transform: rotate(0)
  }
  10% {
    //transform: scale(1.1)
  }
  20% {
    // transform: rotate(4deg) translateY(-12px)
  }
  40% {
    // transform: rotate(2deg) translateY(0);
    background-color: rgba(31, 229, 0, 0.58);
  }
  60% {
    //transform: rotate(-2deg) translateY(-8px)
  }
  80% {
    background-color: rgba(255, 255, 255, 0);
    // transform: rotate(-1deg) translateY(0)
  }
}


.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}


.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

.fc-header-toolbar {
  margin: 0 !important;
  padding: 5px !important;
}

.gcal-event-blue {
  background-color: #006efc;

  & > a {
    color: white;
  }
}

.gcal-event-green {
  background-color: #23e323;

  & > a {
    color: white;
  }
}

.gcal-event-red {
  background-color: #e32323;
}

/*
html, body, #root, .layout-wrap {
  height: 100%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1;
  background-color: #f9f9f9;
}

.wrap {
  min-height: 100%;
  height: auto;
  margin: 0 auto -60px;
  padding: 0 0 60px;
}

.footer {
  height: 60px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  padding: 20px 0 0 0;

  & > .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.PhoneInputInput {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: 9px;
  color: #495057;
}

.loader {
  display: flex;
  justify-content: center;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.model-actions {
  & > a {
    margin-right: 5px;
  }
}

.table {
  margin-top: 20px;

  & > tbody {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > tr {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 100%;

      & > td {
        word-break: break-all;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  & > thead {
    display: flex;
    flex-direction: column;

    & > tr {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 100%;

      & > th {
        width: 100%;
      }
    }
  }
}

.data-provider-control {
  width: calc(69px + 1.5rem);
  vertical-align: center;
}

.data-provider-control > a, .data-provider-control > span {
  margin-right: 5px;
}

.model-control-icon {
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.model-delete-icon {
  cursor: pointer;
  color: #dc3545;

  &:hover {
    color: #c82333;
  }
}

.page-circles {
  font-size: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0 4px;

  & > svg {
    margin: 0 1px;
    color: #dee2e6;
  }
}

.main-container {
  margin: 20px 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-block {
  tbody {
    & > tr {
      & > td {
        word-break: break-all;
      }
    }
  }
}

form {
  div {
    &.required {
      label {
        &.control-label {
          &:after {
            content: " * ";
            color: red;
          }
        }
      }
    }
  }
}

label {
  &.required {
    &:after {
      content: " *";
      color: red;
    }
  }
}

.white-block {
  height: auto;
  min-height: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px 25px;
  margin: 15px 0;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(150, 150, 150, 1);
  -moz-box-shadow: 0px 0px 15px -10px rgba(150, 150, 150, 1);
  box-shadow: 0px 0px 15px -10px rgba(150, 150, 150, 1);
}

.breadcrumb {
  background-color: inherit !important;
  margin: 0 !important;
}

.index-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #007bff;
  min-height: 300px;
}

.index-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(60px, 1fr) );
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    word-break: break-word;
    width: 100%;
    padding-bottom: 8px;
    align-items: flex-start;
    text-align: inherit;

    & > h5 {
      margin-bottom: 0;
    }
  }
}

.grid-container-header {
  background-color: #f9fafc;
  font-size: 1.05rem;
  font-weight: 700;
  margin: 0 !important;
  display: none;
}

.grid-container-line {
  border-bottom: 1px solid #f4f4f4;
}

.grid-item {
  height: 50%;
  padding: 0 2px;
  margin: 5px 0;
  width: auto;
  justify-content: space-between !important;
  border: none;

  & > div {
    &:last-child {
      text-align: right;
      word-break: break-word;
    }
  }
}

.action-buttons > a, .action-buttons > span {
  margin-right: 5px;
}

.tooltip-title {
  display: none;
  word-break: normal;
  font-weight: 500;
  display: block;
}

.pagination {
  margin-top: 1rem;
  margin-bottom: 0;
  flex-wrap: wrap;
}

.page-item {
  margin: 0 2px;
}

.page-link {
  color: #2f3640 !important;
  border: none !important;
  background-color: transparent !important;
  border-radius: 4px !important;
  min-width: 2.25rem;
  width: auto;
  height: 2.25rem;
  text-align: center;
  transition: all 0.3s ease-in-out;

  &.active {
    background-color: #007bff !important;
    border-color: #007bff;
    color: #fff !important;
    font-weight: 700;
  }

  &:hover {
    background-color: #007bff !important;
    border-color: #007bff !important;
    color: #fff !important;
  }

  &:focus {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.modal-confirmation {
  & > .modal-dialog {
    color: #636363;
    max-width: 400px !important;
    margin: 30px auto;

    & > .modal-content {
      padding: 20px;
      border-radius: 5px;
      border: none;
      text-align: center;

      & > .modal-header {
        border-bottom: none;
        position: relative;
        padding: 15px;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & > h5 {
          word-break: break-word;
        }

        & > .modal-header-icon {
          margin-bottom: 20px;
          font-size: 80px;
          color: #f15e5e;
        }
      }

      & > .modal-footer {
        border: none;
        text-align: center;
        border-radius: 5px;
        font-size: 13px;
        padding: 10px 15px 25px;

        & > button {
          border: none;
          min-height: 40px;
          min-width: 120px;
        }
      }
    }
  }
}

.modal-body {
  word-break: break-word;
}

.modal-footer {
  justify-content: space-evenly !important;
}

.modal-dialog {
  max-width: 635px !important;
}

.placeholder-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  & > div {
    padding: 5px;
    text-align: center;
  }
}



.action-buttons {
  justify-content: space-evenly !important;
}
*/
