

$brand-primary: #57c150;
$link-color: #23527c;
$body-bg: #f2f2f2;
$font-family-base: Roboto, Arial, sans-serif;
$font-family-header-quote: Kurale, Arial, sans-serif;
$font-size-base: 0.85rem; //14px;
/*
$container-large-desktop: 1024px;
$container-lg: 1024px;
*/

$header-height: 38.2%;
$header-height-big: 61.2%;
//$header-cover-background: fade(darken($brand-primary, 30%), 40%);
$header-cover-background: rgba(35, 89, 31, .4);
$header-quote-font-size: 45px;
$header-quote-author-font-size: 13px;
$header-quote-color: #ffffff;
$navigation-top-margin: 32px;
$navigation-color: #ffffff;
$navigation-bg-color-hover: darken($brand-primary, 20%);
$navigation-bg-color: fade(darken(desaturate($brand-primary, 35%), 25%), 60%);
$navigation-border-radius: 22px;
$navigation-logotype-size: 44px; //50
$navigation-logotype-border: 5px; //4
$navigation-link-width: 100px;
$navigation-link-count: 6;
$navigation-before-logotype: 30px; //20
$navigation-width: calc($navigation-link-width * $navigation-link-count + $navigation-before-logotype);
$navigation-logotype-left: calc($navigation-width / 2 - $navigation-logotype-border - $navigation-logotype-size / 2);
$navigation-mobile-width: 220px;
$navigation-mobile-transition-duration: 0.3s;

$event-title-color: #ffffff;
$event-title-background: $brand-primary;
$event-panel-height: 42px;
$event-panel-background: #f9f9f9;
$event-panel-border-color: #dfdfdf;
$event-panel-font-color: #808080;
$event-content-background: #ffffff;

$graditude-font-color: #808080;
$graditude-links-font-color: $brand-primary;

$about-slide-nav-font-color: #ffffff;
$about-slide-nav-background: fade(#000000, 40%);
$about-nav-item-color: #ffffff;
$about-background-color: #ffffff;
$about-price-font-size: 22px;

$feed-header-color: #808080;

$footer-logotype-size: 100px;
$footer-time-font-color: darken($brand-primary, 20%);
$footer-background: $brand-primary;
$footer-base-font-color: #ffffff;

$rent-control-border-color: #cccccc;
$rent-control-active-border-color: #000000;
$rent-description-font-color: #ffffff;
$rent-room-info-font-size: 16px;
$rent-room-description-background: fade(#000000, 40%);
$rent-contact-font-color: #000000;

$address-block-background: #ffffff;
$address-block-width: 250px;
$contacts-house-font-size: 20px;
$contacts-how-to-go-color: #ffffff;
$contacts-link-color: $link-color;
$contacts-social-container-background: #7669cb;
$contacts-social-font-size: 43px;
$contacts-social-background: #aa9dff;
$contacts-social-size: 85px;
$contacts-social-color: #ffffff;
$contacts-partners-container-background: #4a5b53;
$contacts-partners-font-color: #77a791;
$contacts-blazon-font-color: #979797;
$contacts-street-sign-size: 165px;

$ribbon-content-font-size: 24px;

$screen-xs-max: 900px;//map-get($container-max-widths, 'lg');


.event {
  height: 100%;
}

.event-cover {
  position: relative;
  //height: 100%;
  height: 500px;
  background-position: center;
  background-size: cover;
}

.event-title-container {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  text-align: center;
  vertical-align: middle;
  color: $event-title-color;

  .ribbon {
    @media (max-width: $screen-xs-max) {
      width: 100%;
      padding: 15px;
      text-align: left;
      &:after,
      &:before,
      .ribbon-content:after,
      .ribbon-content:before {
        display: none;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    top: inherit;
    bottom: $event-panel-height;
    text-align: left;
  }
}

.event-panel-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $event-panel-height;
  border-top: 1px solid $event-panel-border-color;
  border-bottom: 1px solid $event-panel-border-color;
  background: $event-panel-background;
}

.event-title-date {
  display: inline-block;
  margin-top: 80px;
  padding: 15px;
  border-radius: 3px;
  background: rgba(65,82,64,.6);;

  @media (max-width: $screen-xs-max) {
    width: 100%;
    margin-top: 0;
    border-radius: 0;
    background: darken($brand-primary, 20%);
  }
}

.event-panel {
  color: $event-panel-font-color;

  //.container();
}

.event-panel-item {
  display: inline-block;
  padding: 10px 20px;
  border-left: 1px solid $event-panel-border-color;

  &:first-child {
    padding-left: 0;
    border-left: 0;
  }

  @media (max-width: $screen-xs-max) {
    padding-right: 3px;
    padding-left: 3px;

    &.community {
      display: none;
    }
  }
}

.event-content-container {
  padding-top: 20px;
  padding-bottom: 20px;
  background: $event-content-background;
}

.event-content {
  // .container();
}

.event-gratitude {
  margin-top: 20px;
  color: $graditude-font-color;
}

.event-gratitude-link {
  text-decoration: none;
  color: $graditude-links-font-color;

  &:hover {
    color: darken($graditude-links-font-color, 15%);
  }
}

.event-panel-social {
  padding: 0;
}

.event-panel-social-item {
  display: inline-block;
  padding: 10px 0;
  color: $event-panel-font-color;
  font-size: 18px;

  &:hover {
    color: $brand-primary;
  }

  &.facebook {
    margin-left: 7px;
    padding-left: 7px;
    border-left: 1px solid $event-panel-border-color;
    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }
}
