//
// Plugin: Bootstrap Slider
//

// Tooltip fix
.slider .tooltip.in {
  opacity: $tooltip-opacity;
}

// Style override
.slider {
  &.slider-vertical {
    height: 100%;
  }

  &.slider-horizontal {
    width: 100%;
  }
}

// Colors
@each $name, $color in $theme-colors {
  .slider-#{$name} .slider {
    .slider-selection {
      background: $color;
    }
  }
}

@each $name, $color in $colors {
  .slider-#{$name} .slider {
    .slider-selection {
      background: $color;
    }
  }
}

@include dark-mode() {
  .slider-track {
    background-color: lighten($dark, 10%);
    background-image: none;
  }

  @each $name, $color in $theme-colors-alt {
    .slider-#{$name} .slider {
      .slider-selection {
        background: $color;
      }
    }
  }

  @each $name, $color in $colors-alt {
    .slider-#{$name} .slider {
      .slider-selection {
        background: $color;
      }
    }
  }
}
