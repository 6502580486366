//@import "~bootstrap/scss/bootstrap";

/*@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';*/

@import 'AdminLTE/adminlte';

@import '~react-toastify/dist/ReactToastify.min.css';

@import "~slick-carousel/slick/slick.css";
@import "./slick-theme.css";
@import "./components/ribbon.scss";
@import "./components/siteView.scss";
@import "./timeSelector";

@import "app";