.popup {
  visibility: hidden;
  opacity: 0;
  transition: visibility 250ms, opacity 250ms ease-in;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.5);
}

.popup_opened {
  transition: visibility 200ms, opacity 200ms ease-out;
  visibility: visible;
  opacity: 1;
}

.popup__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 35px;
  max-width: 358px;
  background-color: white;
  color: black;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .popup__inner {
    width: 238px;
    padding: 25px 22px;
  }
}

.popup__button-close {
  cursor: pointer;
  display: flex;
  aspect-ratio: 1;
  background: url("../images//svg/cross.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  border: 0;
  position: absolute;
  width: 32px;
  top: -40px;
  right: -40px;
}

@media screen and (max-width: 600px) {
  .popup__button-close {
    width: 20px;
    right: 0px;
    top: -36px;
  }
}

.popup__button-close:hover {
  opacity: 0.6;
}

.popup__image_el_info-tolltip {
  margin: 26px auto 0;
  aspect-ratio: 1;
  width: 120px;
}

.popup__title {
  margin: 0 0 48px;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
}

@media screen and (max-width: 600px) {
  .popup__title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 75px;
  }
}

.popup__title_el_info-tolltip {
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  margin-bottom: 23px;
  text-align: center;
}

.popup__form {
  display: flex;
  flex-direction: column;
}

.popup__form-button-submit {
  cursor: pointer;
  margin-top: 48px;
  border: 1px solid black;
  border-radius: 2px;
  padding: 13px 0;
  background-color: black;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: white;
}

@media screen and (max-width: 600px) {
  .popup__form-button-submit {
    font-size: 14px;
    line-height: 17px;
  }
}

.popup__form-button-submit:hover {
  opacity: 0.8;
}

.popup__form-input {
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 0 0 9px;
  border-bottom: 1px solid rgba(00, 00, 00, 0.2);
}
