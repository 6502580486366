
.pickerConatiner {
  align-self: center;
  width: 75%;
  height: 350px;
  flex-direction: row;
  justify-content: space-between;

  /*  &: {
      alignSelf: "center";
      width: 75%;
      height: 350px;
      flexDirection: "row";
      justifyContent: "space-between";;
    }*/


  .scrollContainer {
    width: 49%;
    height: 350px;
    // borderWidth: 1;
  }
;

  .timeView {
    //text-align: center;
    align-self: center;
    width: 100%;
  }

  .timeText {
    font-size: 32px;
    color: deeppink;
    align-self: center;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .selectedView {
    height: 130px;
    width: 100%;
    background-color: black;
    position: absolute;
    top: 115px;
    z-index: 99;
    border-radius: 10px;
  }
;

  .selectedText {
    align-self: center;
    font-size: 50px;
    font-weight: 400;
    top: 40px;
    color: deeppink;
  }

  .selectedLabel {
    color: #00FF00;
    align-self: flex-end;
    top: 35px;
    right: 10px;
  }

  button {
    width: 200px;
    height: 50px;
    justify-content: center;
    align-self: center;
    text-align: center;
    margin-top: 30px;
    border-radius: 20px;
    border-color: deeppink;
  }

  .buttonText {
    font-size: 20px;
    text-align: center;
    align-self: center;
    color: deeppink;
  }
}


