.errors-wrapper {
  margin: 20px;
  padding: 10px 20px;

  animation: scaleUp .5s;

  border: 2px solid $_color-delete;
  border-radius: 4px;
  background: rgba($_color-delete, .1);

  .error-wrapper {
    margin: 0 0 10px 0;
  }

  .errors {
    h4 {
      font-size: 14px;

      margin: 0;

      @include text_code();
    }

    small {
      color: $errors-wrapper-errors-small-font-color;
    }

    .message {
      white-space: pre-line;

      &.thrown {
        max-width: 100%;
      }
    }

    .error-line {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  hgroup {
    display: flex;

    align-items: center;

    h4 {
      font-size: 20px;

      margin: 0;

      flex: 1;
      @include text_headline();
    }
  }
}


@keyframes scaleUp {
  0% {
    transform: scale(.8);

    opacity: 0;
  }
  100% {
    transform: scale(1);

    opacity: 1;
  }
}
