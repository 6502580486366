select {
  font-size: 14px;
  font-weight: bold;

  padding: 5px 40px 5px 10px;

  border: 2px solid $form-select-border-color;
  border-radius: 4px;
  background: $form-select-background-color url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13.418 7.859c.271-.268.709-.268.978 0 .27.268.272.701 0 .969l-3.908 3.83c-.27.268-.707.268-.979 0l-3.908-3.83c-.27-.267-.27-.701 0-.969.271-.268.709-.268.978 0L10 11l3.418-3.141z"/></svg>') right 10px center no-repeat;
  background-size: 20px;
  box-shadow: 0 1px 2px 0 rgba($form-select-box-shadow-color, .25);

  @include text_headline();
  appearance: none;

  &[multiple] {
    margin: 5px 0;
    padding: 5px;

    background: $form-select-background-color;
  }

  &.invalid {
    @include invalidFormElement();
  }
}

.opblock-body select {
  min-width: 230px;
  @media (max-width: 768px) {
    min-width: 180px;
  }
  @media (max-width: 640px) {
    width: 100%;
    min-width: 100%;
  }
}

label {
  font-size: 12px;
  font-weight: bold;

  margin: 0 0 5px 0;

  @include text_headline();
}

input[type=text],
input[type=password],
input[type=search],
input[type=email],
input[type=file] {
  line-height: 1;

  @media (max-width: 768px) {
    max-width: 175px;
  }
}


input[type=text],
input[type=password],
input[type=search],
input[type=email],
input[type=file],
textarea {
  min-width: 100px;
  margin: 5px 0;
  padding: 8px 10px;

  border: 1px solid $form-input-border-color;
  border-radius: 4px;
  background: $form-input-background-color;


  &.invalid {
    @include invalidFormElement();
  }

}

input,
textarea,
select {
  &[disabled] {
    // opacity: 0.85;
    background-color: #fafafa;
    color: #888;
    cursor: not-allowed;
  }
}

select[disabled] {
  border-color: #888;
}

textarea[disabled] {
  background-color: #41444e;
  color: #fff;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

textarea {
  font-size: 12px;

  width: 100%;
  min-height: 280px;
  padding: 10px;

  border: none;
  border-radius: 4px;
  outline: none;
  background: rgba($form-textarea-background-color, .8);

  @include text_code();

  &:focus {
    border: 2px solid $form-textarea-focus-border-color;
  }

  &.curl {
    font-size: 12px;

    min-height: 100px;
    margin: 0;
    padding: 10px;

    resize: none;

    border-radius: 4px;
    background: $form-textarea-curl-background-color;

    @include text_code($form-textarea-curl-font-color);
  }
}


.checkbox {
  padding: 5px 0 10px;

  transition: opacity .5s;

  color: $form-checkbox-label-font-color;

  label {
    display: flex;
  }

  p {
    font-weight: normal !important;
    font-style: italic;

    margin: 0 !important;

    @include text_code();
  }

  input[type=checkbox] {
    display: none;

    & + label > .item {
      position: relative;
      top: 3px;

      display: inline-block;

      width: 16px;
      height: 16px;
      margin: 0 8px 0 0;
      padding: 5px;

      cursor: pointer;

      border-radius: 1px;
      background: $form-checkbox-background-color;
      box-shadow: 0 0 0 2px $form-checkbox-box-shadow-color;

      flex: none;

      &:active {
        transform: scale(.9);
      }
    }

    &:checked + label > .item {
      background: $form-checkbox-background-color url('data:image/svg+xml, <svg width="10px" height="8px" viewBox="3 7 10 8" version="1.1" xmlns="http://www.w3.org/2000/svg"><polygon id="Rectangle-34" stroke="none" fill="#41474E" fill-rule="evenodd" points="6.33333333 15 3 11.6666667 4.33333333 10.3333333 6.33333333 12.3333333 11.6666667 7 13 8.33333333"></polygon></svg>') center center no-repeat;
    }
  }
}
