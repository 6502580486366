.chat {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
}

.chat__messages {
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
  flex: 1 1 auto;
}

.chat__message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.message {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
}

.message.message_user .message__bubble {
  background-color: #DCF8C6;
}

.message.message_assistant .message__bubble {
  background-color: #F2F2F2;
}

.message__bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  max-width: 90%;
}

.message__text {
  font-size: 14px;
  color: #444;
  white-space: pre-wrap;
}

.message_user {
  justify-content: flex-end;
}

.message_assistant {
  justify-content: flex-start;
}

/*----------------------------------------------------------------*/

.chat-input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

.chat-input__input {
  width: 100%;
  resize: none;
  padding: 10px;
  border: none;
  background-color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 16px;
  height: auto;
  min-height: 20px;
  max-height: 120px;
  overflow-y: auto;
}

.chat-input__btn-send {
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
